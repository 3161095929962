import React from 'react';
import { useTranslation } from 'react-i18next';

const LanguageSwitcher = () => {
    const { i18n } = useTranslation();

    // Используем resolvedLanguage для получения текущей локали
    const currentLanguage = i18n.resolvedLanguage;

    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
    };

    return (
        <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }} >
            <button
                onClick={() => changeLanguage('uk')}
                style={{
                    fontWeight: currentLanguage === 'uk' ? 'bold' : 'normal',
                    border: 'none',
                    background: 'none',
                    cursor: 'pointer',
                }}
            >
                UA
            </button>
            <span>|</span>
            <button
                onClick={() => changeLanguage('en')}
                style={{
                    fontWeight: currentLanguage === 'en' ? 'bold' : 'normal',
                    border: 'none',
                    background: 'none',
                    cursor: 'pointer',
                }}
            >
                EN
            </button>
        </div>
    );
};

export default LanguageSwitcher;