import React from "react";
import {useTranslation} from "react-i18next";

export default function EducationRow(props) {
    const {t} = useTranslation();
    const {education} = props;
    return (
        <div className="flex gap-3 justify-start items-start ">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                 xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#clip0_60_4435)">
                    <path
                        d="M2.7336 10.1839H20.0113V7.86164H18.9126C18.1763 6.07409 18.4824 4.22863 18.7953 3.15624H20.0113V0.833252H2.7336C1.2273 0.833252 0 2.05228 0 3.55933V7.46457C0 8.97162 1.2273 10.1839 2.7336 10.1839ZM2.32149 3.55933C2.32149 3.34425 2.51852 3.15549 2.7336 3.15549H16.9663C16.6753 4.34669 16.4963 6.07333 17.0446 7.86089H2.7336C2.51852 7.86089 2.32149 7.67965 2.32149 7.46457V3.55933Z"
                        fill="#418F44"/>
                    <path
                        d="M20.8904 15.0088H4.16846V17.331H4.73022C5.2446 18.9983 4.84001 20.2496 4.56176 20.8445H4.16846V23.1667H20.8904C22.399 23.1667 23.6428 22.0725 23.6428 20.7084V17.4544C23.6428 16.0909 22.399 15.0088 20.8904 15.0088ZM20.8904 20.8445H6.44482C6.71405 20.012 6.89303 18.7606 6.55612 17.3295H20.8911C21.1348 17.3295 21.2672 17.4416 21.283 17.4574L21.289 20.6888C21.2664 20.7294 21.1348 20.8445 20.8904 20.8445Z"
                        fill="#418F44"/>
                    <path
                        d="M20.9957 10.8396L19.3555 11.842V10.8396H0.118042V14.2936H19.3555V13.31L20.9957 14.2936H22.8817C23.4991 14.2936 24 13.7928 24 13.1746V11.7729C24 11.2577 23.5811 10.8396 23.0675 10.8396H20.9957Z"
                        fill="#418F44"/>
                </g>
                <defs>
                    <clipPath id="clip0_60_4435">
                        <rect width="24" height="24" fill="white"/>
                    </clipPath>
                </defs>
            </svg>
            <div className="text-[#06011e] text-sm leading-[150%]">
                <span className="font-extrabold inline-block pr-2">
                    {t('interpretation_result.education')}
                    :
                </span>
                {education}
            </div>
        </div>
    )
}