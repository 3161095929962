import React from "react";
import {useTranslation} from "react-i18next";

export default function OneResearchResult(props) {
    const {oneResearch} = props;
    const {test_name, interpretation, is_abnormal, recommendations, reference_range, result, unit} = oneResearch;
    const {t} = useTranslation();
    return (
        <div className={`border-design ${is_abnormal ? 'border-design--type3' : 'border-design--type2'} bg-white rounded`}>
            <div className={`flex justify-between px-10 py-6 ${is_abnormal ? 'bg-[#fcf6f4]' : 'bg-[#f5f9f5]'} max-md:px-4 max-md:py-6`}>
                <div className="text-2xl font-bold leading-[120%] max-xl:text-xl max-md:text-lg">
                    {test_name}
                </div>
                <div className="flex justify-end items-center gap-2">
                    <div className={`w-2 h-2 ${is_abnormal ? 'bg-[#c3522f]' : 'bg-[#418f44]'} rounded-sm`}></div>
                    <div className={`${is_abnormal ? 'text-[#c3522f]' : 'text-[#418f44]'} text-xl font-bold  leading-[150%] max-md:text-sm`}>
                        {result} {unit}
                    </div>
                </div>
            </div>
            <div className="px-10 py-3 text-[#808080] text-base leading-normal text-left max-md:text-sm max-md:p-3">
                <p className="text-1xl font-bold text-black mb-2">
                    {t('interpretation_result.reference_range')}:
                    {reference_range}</p>
                {interpretation}
            </div>
        </div>
    )
}