import React, {useState} from 'react';
import {Dropzone, MIME_TYPES} from '@mantine/dropzone';
import {Group, Text} from '@mantine/core';
import IconUpload from "./IconUpload";
import IconFile from "./IconFile";
import './gradient.scss';
import IconRemoveFile from "./IconRemoveFile";
import {useTranslation} from "react-i18next";


function FileUploader(props) {
    const { t } = useTranslation();
    const { files, SetFiles } = props;
    const [error, SetError] = useState(null);
    const handleDrop = (acceptedFiles) => {
        SetError(null);
        SetFiles([...files, ...acceptedFiles]);
    };
    const handleReject = (fileRejections) => {
        if (files.length + fileRejections.length > 10) {
            SetError(t('interpretation.files_field_counts_error'));
            return;
        } else {
            SetError(null);
        }

        const exceededSize = fileRejections?.some(
            (file) => file.errors?.some((error) => error.code === "file-too-large")
        );

        if (exceededSize) {
            SetError(t('interpretation.files_field_file_size_error'));
        } else {
            SetError(t('interpretation.files_field_counts_error'));
        }
    };

    const handleRemove = (index) => {
        SetFiles(files.filter((_, i) => i !== index));
    };
    return (
        <div style={{width: '100%', margin: 'auto'}}>
            <Dropzone
                onDrop={handleDrop}
                onReject={handleReject}
                maxFiles={10}
                maxSize={5 * 1024 * 1024}
                accept={[MIME_TYPES.pdf]}
                styles={{
                    root: {
                        padding: '16px 0px',
                        fontsize: '16px',
                        fontWeight: '600 !important',
                        lineHeight: '24px',
                        color: 'white'
                    }
                }}
            >
                <Group position="center" spacing="xs" style={{cursor: 'pointer'}}>
                    <IconUpload size={24}/>
                    <div className="text-white text-base font-semibold leading-normal text-left">
                        {t('interpretation.files_field_file_upload_text')}
                        <span className="text-[#595353]" style={{fontSize: '14px', color: ''}}>
                            {t('interpretation.files_field_file_upload_info')}
                        </span>
                    </div>
                </Group>
            </Dropzone>
            {error && (
                    <p className="form-review-error text-left"> {error} </p>
            )}
            {files.length > 0 && (
                <div style={{marginTop: 16}}>
                    {files.map((file, index) => (
                        <div className="gradient" style={{margin: '16px 0px'}} key={index}>
                            <Group  position="apart" style={{padding: '16px 0', marginLeft:'24px'}}>
                                <IconFile size={24}/>
                                <Text style={{color: 'white', fontSize: '16px', fontWeight: '400'}}>{file.name}</Text>
                                <IconRemoveFile size={24} onClick={() => {
                                    handleRemove(index)
                                }}/>
                            </Group>
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
}

export default FileUploader;