import React from 'react';
import {useModal} from "../../../../../../../providers/ModalProvider";
import {useTranslation} from "react-i18next";


function MakeAnAppointmentLink() {
    const {t} = useTranslation();
    const { open } = useModal();

    const handleClick = (e) => {
        e.preventDefault();
        open();
    };

    return (
        <button className="button" onClick={handleClick}>
            {t('recommended_healers.make_an_appointment')}
        </button>
    );
}

export default MakeAnAppointmentLink;