import React, {useState} from "react";
import {useForm} from "react-hook-form";
import {toast} from "react-toastify";
import ReCAPTCHA from "react-google-recaptcha";
import * as emailjs from "emailjs-com";
import {useTranslation} from "react-i18next";

export default function ContactUsForm() {
    const {t} = useTranslation();
    const {
        register,
        setValue,
        setError,
        clearErrors,
        handleSubmit,
        reset,
        formState,
        formState: {errors, isSubmitting },
    } = useForm();

    const [recaptchaToken, setRecaptchaToken] = useState(null);

    const onSubmit = handleSubmit(async ({name, email, comment}) => {
        if (!recaptchaToken) {
            toast.error(t('contactus.recaptcha_confirm'));
            return;
        }

        console.log('Submitting form...');
        try {
            const result = await emailjs.send(
                process.env.REACT_APP_EMAILJS_SERVICE_ID,
                process.env.REACT_APP_EMAILJS_CONTACTUS_TEMPLATE_ID,
                { name, email, comment },
                process.env.REACT_APP_EMAILJS_PUBLIC_KEY
            );
            toast.success(t('contactus.recaptcha_success'));
            console.log("Email sent successfully:", result.text);
        } catch (error) {
            console.error("Error sending email:", error.text);
            toast.error(t('contactus.recaptcha_send_error'));
        }
        console.log('Submission complete');
    });

    const handleRecaptchaChange = (token) => {
        setRecaptchaToken(token);
    };

    React.useEffect(() => {
        if (formState.isSubmitSuccessful) {
            reset({ name: '', email:'', comment:'' });
        }
    }, [formState, reset]);

    return (
        <>
            <div className="w-full flex flex-col gap-2">
                <div className="text-white text-base font-semibold leading-normal text-left">
                    {t('contactus.name_field')}
                </div>
                {errors?.name?.type === "required" && (
                    <p className="form-review-error"> {t('contactus.name_field_filled')} </p>
                )}
                {errors?.name?.type === "maxLength" && (
                    <p className="form-review-error">
                        {t('contactus.name_field_many_letters')}
                    </p>
                )}
                {errors?.name?.type === "pattern" && (
                    <p className="form-review-error">{t('contactus.name_field_pattern')}</p>
                )}
                <input
                    type="text"
                    {...register("name", {
                        required: true,
                        maxLength: 28,
                        pattern: /^[A-Za-zА-Яа-яЁёІіЇїЄєҐґ'’ʼ\s-]+$/
                    })}
                    placeholder={t('contactus.name_field_placeholder')}
                    className="h-12 px-4 py-3 rounded border border-[#b8b5b5] text-white text-base font-normal leading-normal text-left bg-transparent placeholder:text-[#595353]"/>
            </div>
            <div className="w-full flex flex-col gap-2">
                <div className="text-white text-base font-semibold leading-normal text-left">{t('contactus.email_field')}</div>
                {errors?.email?.type === "required" && (
                    <p className="form-review-error"> {t('contactus.email_field_filled')} </p>
                )}
                {errors?.email?.type === "maxLength" && (
                    <p className="form-review-error">
                        {t('contactus.email_field_many_letters')}
                    </p>
                )}
                {errors?.email?.type === "pattern" && (
                    <p className="form-review-error">{t('contactus.email_field_pattern')}</p>
                )}
                <input
                    type="email"
                    {...register("email", {
                        required: true,
                        maxLength: 64,
                        pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                    })}
                    placeholder="example@gmail.com"
                    className="h-12 px-4 py-3 rounded border border-[#b8b5b5] text-white text-base font-normal leading-normal text-left bg-transparent placeholder:text-[#595353]"/>
            </div>
            <div className="w-full flex flex-col gap-2">
                <div className="text-white text-base font-semibold leading-normal text-left">{t('contactus.comment_field')}</div>
                {errors?.comment?.type === "required" && (
                    <p className="form-review-error"> {t('contactus.comment_field_filled')} </p>
                )}
                {errors?.comment?.type === "maxLength" && (
                    <p className="form-review-error">
                        {t('contactus.comment_field_many_letters')}
                    </p>
                )}
                {errors?.comment?.type === "pattern" && (
                    <p className="form-review-error">{t('contactus.comment_field_pattern')} </p>
                )}
                <textarea
                    {...register("comment", {
                        required: true,
                        maxLength: 1024,
                        pattern: /^[a-zA-Zа-яА-ЯёЁіІїЇєЄ' ,.!@?-]+$/u,
                    })}
                    type="text"
                    placeholder={t('contactus.comment_field_placeholder')}
                    className="h-48 px-4 py-3 rounded border border-[#b8b5b5] text-white text-base font-normal leading-normal text-left bg-transparent resize-y placeholder:text-[#595353] "/>
            </div>
            <ReCAPTCHA
                sitekey={process.env.REACT_APP_RECAPTCHA_KEY}
                onChange={handleRecaptchaChange}
            />
            <div className="flex items-center gap-2">
                <button className="button" onClick={onSubmit} disabled={isSubmitting}>
                    {t('contactus.comment_send_button')}
                </button>
                {isSubmitting && <img src='/img/spinner.svg' alt="Logo" className="h-6 w-6" />}
            </div>
        </>
    )
}