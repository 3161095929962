import {useModal} from "../../../../../../../../../providers/ModalProvider";
import React from "react";
import {useTranslation} from "react-i18next";

const MakeAnAppointmentSuccsess = (props) => {
    const {t} = useTranslation();
    const {SetAppointmentFormed} = props;
    const {close} = useModal();
    return (
        <div className="popup max-w-[710px] p-20 max-xl:p-12 max-md:py-8 max-md:px-4">
            <div className="flex flex-col justify-center items-center gap-8 max-md:gap-6">
                <div className="text-[#131313] text-5xl font-bold leading-[120%] max-xl:text[32px] max-md:text-2xl">
                    {t('recommended_healers.appointment_success')}
                </div>
                <div className="text-center text-[#131313] text-lg leading-[150%] max-xl:text-base">
                    {t('recommended_healers.clarification_of_details')}
                </div>
                <button className="button" onClick={()=>{
                    SetAppointmentFormed(false);
                    close();
                }}>{t('recommended_healers.return_main_page_button')}</button>
            </div>
        </div>
    );
};

export default MakeAnAppointmentSuccsess;
