import "./App.scss";
import "swiper/css";
import "swiper/css/pagination";
import {Route, Routes, useLocation} from "react-router-dom";

import Header from "../header/Header";
import Footer from "../footer/Footer";

import MainPage from "../../pages/MainPage/MainPage";
import NotFoundPage from "../../pages/NotFoundPage/NotFoundPage";
import PrivacyPolicyPage from "../../pages/PrivacyPolicyPage/PrivacyPolicyPage";
import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import PdfResultPage from "../../pages/PdfResultPage/PdfResultPage";
import {useEffect} from "react";
import { useTranslation } from 'react-i18next';

const trackPageView = (url) => {
    window.gtag("config", "G-5813580480", {
        page_path: url,
    });
};

function App() {
    const { t, i18n } = useTranslation();

    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
    };

    const location = useLocation();

    useEffect(() => {
        trackPageView(location.pathname + location.search);
    }, [location]);

    return (
        <>
            <div className="wrapper">
                <Header/>
                <main>
                    <Routes>
                        <Route path="/" element={<MainPage/>}/>
                        <Route path="/privacy-policy" element={<PrivacyPolicyPage/>}/>
                        <Route path="/pdf-result" element={<PdfResultPage/>}/>

                        <Route path="/*" element={<NotFoundPage/>}/>
                    </Routes>
                </main>
                <Footer/>
                <ToastContainer/>
            </div>
        </>
    );
}

export default App;
