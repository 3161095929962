import React, {useEffect, useState} from "react";
import {useForm} from "react-hook-form";
import Loader from "../../../../../../../components/loader/Loader";
import {Link} from "react-router-dom";
import FileUploader from "./FileUploader";
import {getResultsFromPdf} from "../../../../../../../api/getResultsFromPdf";
import {toast} from "react-toastify";
import {useTranslation} from "react-i18next";

export default function NonClientTabForm(props) {
    const { t } = useTranslation();
    const { i18n } = useTranslation();
    const {clientInterpretation, SetClientInterpretation} = props;
    const {
        register,
        setValue,
        setError,
        clearErrors,
        handleSubmit,
        reset,
        formState,
        formState: {errors, isSubmitting},
    } = useForm();

    const [isUploadData, SetUploadData] = useState(false);
    const [files, SetFiles] = useState([]);

    useEffect(() => {
        if (clientInterpretation === null) {
            reset();
            SetFiles([]);
        }
    }, [clientInterpretation, reset]);

    useEffect(() => {
        if (files.length > 0) {
            clearErrors("files");
        }
    }, [files]);

    const onSubmit = handleSubmit(async (data) => {
        if (files && files.length == 0) {
            setError("files", {
                type: "custom",
                message: t('interpretation.files_field_filled'),
            });
            return;
        } else if(files && files.length >= 10) {
            setError("files", {
                type: "custom",
                message: t('interpretation.files_field_counts'),
            });
            return;
        } else {
            clearErrors("files");
        }

        console.log(data);
        console.log('Submitting form...');
        const formData = new FormData();
        formData.append("anamnesis", data.anamnesis);
        files.forEach((file) => {
            formData.append("file", file);
        });
        formData.append("lang", i18n.resolvedLanguage || i18n.language || 'uk');
        console.log(formData)
        SetUploadData(true);
        let clientResult = await getResultsFromPdf(formData);
        SetUploadData(false);
        console.log(clientResult);
        if(!clientResult) return;
        if (clientResult?.details) {
            toast.error(clientResult?.details);
            return;
        }
        if (clientResult?.errors) {
            toast.error(clientResult?.errors);
            return;
        } else if (!clientResult.errors) {
            SetClientInterpretation(clientResult);
            reset();
            SetFiles([]);
        }
        console.log('Submission complete');
    });

    return (
        <>
            <Loader isLoading={isUploadData}/>
            <form onSubmit={handleSubmit(onSubmit)} style={{display: 'grid', gap: '1rem'}}>
                <div className="w-full flex flex-col gap-2">
                    <div className="text-white text-base font-semibold leading-normal text-left">
                        {t('interpretation.no_client_complaints')}
                    </div>
                    {errors?.anamnesis?.type === "required" && (
                        <p className="form-review-error text-left">
                            {t('interpretation.comment_field_filled')}
                        </p>
                    )}
                    {errors?.anamnesis?.type === "maxLength" && (
                        <p className="form-review-error">
                            {t('interpretation.comment_field_many_letters')}
                        </p>
                    )}
                    {errors?.anamnesis?.type === "pattern" && (
                        <p className="form-review-error text-left">{t('interpretation.comment_field_pattern')} </p>
                    )}
                    <textarea
                        type="text"
                        {...register("anamnesis", {
                            // required: true,
                            maxLength: 1000,
                            pattern: /^[a-zA-Zа-яА-Я\dёЁіІїЇєЄ' ,.!@?-]+$/u,
                        })}
                        placeholder={t('interpretation.comment_field_placeholder')}
                        className="h-48 px-4 py-3 rounded border border-[#b8b5b5] text-white text-base font-normal leading-normal text-left bg-transparent resize-y placeholder:text-[#595353]"/>
                </div>
                {errors?.files && (
                    <p className="form-review-error text-left">{errors.files.message}</p>
                )}
                <FileUploader files={files} SetFiles={SetFiles}/>
                {errors?.terms && (
                    <p className="form-review-error text-left">{errors.terms.message}</p>
                )}
                <label
                    className="w-full mt-6 mb-8 flex items-start gap-2 cursor-pointer select-none max-md:mb-4 max-md:gap-4">
                    <input
                        type="checkbox"
                        {...register("terms", {
                            required: t('interpretation.terms_field_filled'),
                        })}
                        className="hidden-checkbox"/>
                    <div className="checkbox-box"></div>
                    <div className="text-white text-xs leading-[150%] text-left">
                        {t('interpretation.terms_field_left_confirmed')} &nbsp;&nbsp;
                        <Link to="/privacy-policy#privacy" className="text-[#418f44] underline hover:no-underline">
                            {t('interpretation.terms_field_right_confirmed')}
                        </Link>
                    </div>
                </label>
                <button className="button">
                     {t('interpretation.reject_interpretation')}
                </button>
            </form>
        </>
    )
}