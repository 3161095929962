import React from "react";
import OneResearchResult from "./components/oneResearchResult/OneResearchResult";
import {useTranslation} from "react-i18next";

const InterpretationResult = React.forwardRef((props, ref) => {
    const {t} = useTranslation();
    const {clientInterpretation} = props;
    let {data} = clientInterpretation;
    let {results} = clientInterpretation;
    if (!data && results) {
        data = results;
    }

    const openPdfResult = () => {
        localStorage.setItem("clientInterpretation", JSON.stringify(clientInterpretation));
        window.open("/pdf-result", "_blank");
    };

    return (
        <div ref={ref} className="interpretation-result">
            <section className="py-14 max-xl:py-10 max-md:py-6">
                <div className="inside">
                    <div className="mb-2 text-[#b8b5b5] text-base leading-normal">
                        {t('interpretation_result.сheck_yourself')}
                    </div>
                    <div
                        className="mb-12 text-[#00120a] text-[40px] font-bold leading-[130%] max-xl:text-[32px] max-md:text-2xl max-md:mb-8">
                        {t('interpretation_result.research_results')}
                    </div>
                    <div
                        className="mb-12 w-[896px] max-w-full mx-auto text-lg font-normal leading-[150%] max-xl:text-base max-md:mb-8">
                        {t('interpretation_result.conclusion')}
                    </div>
                    <div
                        className="mb-12 grid grid-cols-2 gap-4 max-xl:gap-x-4 max-xl:gap-y-12 max-xl:mb-14 max-[920px]:grid-cols-1 max-md:mb-8">
                        {data?.results.map((oneResearch, index) => <OneResearchResult key={index}
                                                                                      oneResearch={oneResearch}/>)}
                    </div>
                    <div className="mb-6 text-2xl font-bold leading-[120%] text-left max-md:text-lg">
                        {t('interpretation_result.general_recommendations')}
                    </div>
                    <div className="mb-12 text-[#06011e] text-base leading-normal text-left max-md:mb-8">
                        <p className="mb-2">
                            {data?.general_recommendations}
                        </p>
                    </div>
                    <button onClick={openPdfResult} className="button pulsate">
                        {t('interpretation_result.download_results_pdf')}
                    </button>
                </div>
            </section>
        </div>
    );
});

export default InterpretationResult;