import React, {useEffect, useState} from "react";
import NonClientTab from "./components/nonClientTab/NonClientTab";
import ClientTab from "./components/clientTab/ClientTab";
import {useLocation} from "react-router-dom";
import {useTranslation} from "react-i18next";

export default function Interpretation(props) {
    const { t } = useTranslation();
    const {clientInterpretation, SetClientInterpretation} = props;
    const [activeTab, setActiveTab] = useState("nonClient");
    const location = useLocation();
    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const orderId = searchParams.get('order_id');
        if (orderId) {
            setActiveTab("client");
        }
    }, []);
    return (
        <section id="interpretation-section" className="pt-28 pb-20 max-xl:pt-16 max-xl:pb-10 max-md:pt-12 max-md:pb-6">
            <div className="inside">
                <div
                    className="py-24 bg-[#00130A] rounded-2xl bg-[url('/public/img/form-bg.png')] max-xl:mx-[-48px] max-xl:w-[calc(100% + 48px + 48px)] max-xl:rounded-none max-xl:bg-[url('/public/img/form-bg-table.png')] max-xl:py-[4.5rem] max-xl:px-4 max-md:pt-14 max-md:pb-12 max-md:mx-[-16px] max-md:w-[calc(100% + 16px + 16px)] max-md:bg-none">

                    <div className="mb-2 opacity-50 text-center text-[#b8b5b5] text-base leading-normal">
                        {t('interpretation.start')}
                    </div>
                    <div
                        className="mb-8 text-center text-white text-[40px] font-bold leading-[130%] max-xl:text-[32px] max-md:text-2xl">
                        {t('interpretation.fill_form')}
                    </div>
                    <div
                        className="mb-12 mx-auto w-[570px] max-w-full text-center text-white text-lg font-normal leading-[150%] max-md:text-base max-md:mb-8">
                        {t('interpretation.description')}
                    </div>

                    <div className="max-w-[680px] mx-auto mb-14 grid grid-cols-2 max-md:mb-8">
                        <button
                            onClick={() => setActiveTab("nonClient")}
                            className={`py-3 border-b ${activeTab === "nonClient" ? "border-[#418f44]" : "border-white"} text-center text-white text-lg leading-normal transition duration-300 ease-in max-md:py-2 max-md:text-sm`}
                        >

                            {t('interpretation.no_client')}
                        </button>
                        <button
                            onClick={() => setActiveTab("client")}
                            className={`py-3 border-b ${activeTab === "client" ? "border-[#418f44]" : "border-white"} text-center text-white text-lg leading-normal transition duration-300 ease-in max-md:py-2 max-md:text-sm`}
                        >
                            {t('interpretation.client')}
                        </button>
                    </div>

                    <div className="max-w-[480px] mx-auto flex flex-col gap-4 items-center">
                        {activeTab === "nonClient" && (
                            <NonClientTab SetClientInterpretation={SetClientInterpretation} clientInterpretation={clientInterpretation}/>
                        )}

                        {activeTab === "client" && (
                            <>
                                <ClientTab SetClientInterpretation={SetClientInterpretation} clientInterpretation={clientInterpretation}/>
                            </>
                        )}
                    </div>
                </div>
            </div>
        </section>
    );
}