import React from 'react';
import './Loader.scss';
import {useTranslation} from "react-i18next";

export default function Loader ({ isLoading }) {
    const { t } = useTranslation();
    if (!isLoading) return null;
    return (
        <div className="loader-overlay">
            <div className="loader">

            </div>
            <p className="loader-text text-2xl font-bold text-base">{t('main.loader')}</p>
        </div>
    );
};