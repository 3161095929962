import React from "react";
import {useTranslation} from "react-i18next";

export default function ExperienceRow(props) {
    const {t} = useTranslation();
    const {experience} = props;
    return (
        <div className="flex gap-3 justify-start items-start ">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                 xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M17.7844 6.10035L17.7666 6.10879C17.7488 6.11723 17.7309 6.12473 17.7131 6.13176L17.6925 6.13973C17.6747 6.14582 17.6574 6.15145 17.6395 6.15613L17.6189 6.16176C17.5997 6.16598 17.5805 6.16973 17.5613 6.17254L17.5449 6.17535C17.5198 6.17833 17.4946 6.17989 17.4694 6.18004C17.4434 6.17997 17.4174 6.17825 17.3916 6.17488H17.3789C17.3576 6.17211 17.3365 6.1682 17.3156 6.16316L17.2931 6.1566C17.2758 6.15191 17.258 6.14676 17.2411 6.14066C17.2317 6.13738 17.2233 6.13363 17.2144 6.12988C17.1984 6.12379 17.1825 6.11723 17.1675 6.10973L17.1389 6.0952C17.1244 6.08723 17.1094 6.07973 17.0949 6.07129L17.0653 6.05301L17.0231 6.02488C17.0133 6.01832 17.0039 6.01129 16.9941 6.00379C16.9842 5.99629 16.9659 5.9827 16.9523 5.97145L16.9247 5.94895C16.9177 5.94238 16.9102 5.93723 16.9031 5.93066C16.8999 5.9538 16.8983 5.97714 16.8984 6.00051V21.2241C16.8984 21.3688 16.9559 21.5076 17.0583 21.6099C17.1606 21.7122 17.2994 21.7697 17.4441 21.7697C17.5888 21.7697 17.7276 21.7122 17.8299 21.6099C17.9322 21.5076 17.9897 21.3688 17.9897 21.2241V6.00004C17.9897 5.98879 17.9897 5.97754 17.9897 5.96629C17.9474 6.00181 17.9026 6.03423 17.8556 6.06332L17.8449 6.06988C17.8233 6.07926 17.8041 6.09051 17.7844 6.10035Z"
                    fill="#418F44"/>
                <path
                    d="M18.9764 3.60926C18.9693 3.20731 18.8047 2.8242 18.518 2.54243C18.2313 2.26067 17.8453 2.10278 17.4433 2.10278C17.0413 2.10278 16.6554 2.26067 16.3687 2.54243C16.0819 2.8242 15.9173 3.20731 15.9103 3.60926C15.9103 4.26551 16.3382 5.44816 16.9242 5.94832L16.9518 5.97082C16.9654 5.98207 16.9795 5.99285 16.9936 6.00316C17.0076 6.01348 17.0128 6.01769 17.0226 6.02426L17.0648 6.05238L17.0943 6.07066C17.1089 6.0791 17.1239 6.0866 17.1384 6.09457L17.167 6.1091C17.1825 6.1166 17.1984 6.12316 17.2139 6.12926C17.2228 6.13301 17.2312 6.13676 17.2406 6.14004C17.2575 6.14613 17.2753 6.15129 17.2926 6.15598L17.3151 6.16254C17.336 6.16757 17.3571 6.17149 17.3784 6.17426H17.3911C17.4169 6.17762 17.4429 6.17934 17.4689 6.17941C17.4941 6.17927 17.5193 6.1777 17.5443 6.17473L17.5607 6.17191C17.58 6.1691 17.5992 6.16535 17.6184 6.16113L17.639 6.15551C17.6568 6.15082 17.6742 6.14519 17.692 6.1391L17.7126 6.13113C17.7304 6.1241 17.7482 6.1166 17.7661 6.10816L17.7839 6.09973C17.8036 6.08988 17.8228 6.07863 17.8425 6.06738L17.8532 6.06082C18.5076 5.65816 18.9764 4.32082 18.9764 3.60926Z"
                    fill="#418F44"/>
                <path
                    d="M13.8028 0.796875H6.16965C5.69201 0.796875 5.30481 1.18408 5.30481 1.66172V3.26672C5.30481 3.74436 5.69201 4.13156 6.16965 4.13156H13.8028C14.2804 4.13156 14.6676 3.74436 14.6676 3.26672V1.66172C14.6676 1.18408 14.2804 0.796875 13.8028 0.796875Z"
                    fill="#418F44"/>
                <path
                    d="M9.98438 23.2032C10.8742 23.2022 11.7273 22.8482 12.3565 22.219C12.9857 21.5898 13.3396 20.7367 13.3406 19.8469V4.69409H6.63V8.05081H8.27391C8.61778 8.05081 8.94757 8.18741 9.19072 8.43056C9.43387 8.67372 9.57047 9.0035 9.57047 9.34737V16.3153C9.57053 16.4856 9.53704 16.6543 9.47191 16.8117C9.40678 16.969 9.31129 17.112 9.19089 17.2325C9.07048 17.3529 8.92753 17.4484 8.77019 17.5136C8.61286 17.5788 8.44422 17.6124 8.27391 17.6124H6.63V19.8469C6.631 20.7364 6.98466 21.5892 7.61346 22.2184C8.24226 22.8475 9.09488 23.2017 9.98438 23.2032Z"
                    fill="#418F44"/>
            </svg>
            <div className="text-[#06011e] text-sm leading-[150%]">
                <span className="font-extrabold inline-block pr-2">
                    {t('interpretation_result.experience')}
                </span>
                {experience} {t('interpretation_result.years')}
            </div>
        </div>
    )
}