import {Swiper, SwiperSlide} from "swiper/react";
import {Pagination} from "swiper/modules";
import React from "react";
import {useTranslation} from "react-i18next";

export default function ClientReviews() {
    const {t} = useTranslation();
    return (
        <section
            className="py-20 max-xl:py-10 max-md:py-6 bg-[url('/public/img/section-bg2.svg')] bg-no-repeat bg-center bg-contain">
            <div className="inside">
                <div className="mb-4 text-[#b8b5b5] text-base leading-normal">
                    {t('honest_reviews.title')}
                </div>
                <div
                    className="mb-12 text-[#00120a] text-[40px] font-bold leading-[130%] max-xl:text-[32px] max-xl:mb-14 max-md:text-2xl max-md:mb-8">
                    {t('honest_reviews.what_users_say')}
                </div>
                <Swiper
                    className="swiper-review mb-6 max-xl:mb-0"
                    spaceBetween={24}
                    slidesPerView={'auto'}
                    centeredSlides={true}
                    pagination={{clickable: true}}
                    modules={[Pagination]}
                    breakpoints={{
                        1280: {
                            slidesPerView: 3,
                            centeredSlides: false,
                        },
                    }}
                >
                    <SwiperSlide className="slide border border-design border-transparent p-8 text-left rounded-lg max-md:pr-4">
                        <div
                            className="mb-6 text-[#06011e] text-base leading-normal max-md:text-sm max-md:leading-[150%] max-md:mb-6">
                            {t('honest_reviews.review_one_text')}
                        </div>
                        <div className="flex justify-between items-start gap-4">
                            <div className="flex items-center gap-6">
                                <div className="min-w-12 w-12 h-12 rounded-full">
                                    <img className="w-full h-full object-cover rounded-full" src="/img/dasha_gorchilina.jpg"/>
                                </div>
                                <div className="mt-[-8px] flex flex-col gap-1">
                                    <div
                                        className="text-[#06011e] text-base font-bold leading-tight max-md:text-sm">
                                        {t('honest_reviews.review_one_author')}
                                    </div>
                                    <div className="flex gap-2 items-center">
                                        <div className="flex justify-start items-center">
                                            <svg width="16" height="15" viewBox="0 0 16 15" fill="none"
                                                 xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M8 0L9.79611 5.52786H15.6085L10.9062 8.94427L12.7023 14.4721L8 11.0557L3.29772 14.4721L5.09383 8.94427L0.391548 5.52786H6.20389L8 0Z"
                                                    fill="#FFBB00"/>
                                            </svg>
                                            <svg width="16" height="15" viewBox="0 0 16 15" fill="none"
                                                 xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M8 0L9.79611 5.52786H15.6085L10.9062 8.94427L12.7023 14.4721L8 11.0557L3.29772 14.4721L5.09383 8.94427L0.391548 5.52786H6.20389L8 0Z"
                                                    fill="#FFBB00"/>
                                            </svg>
                                            <svg width="16" height="15" viewBox="0 0 16 15" fill="none"
                                                 xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M8 0L9.79611 5.52786H15.6085L10.9062 8.94427L12.7023 14.4721L8 11.0557L3.29772 14.4721L5.09383 8.94427L0.391548 5.52786H6.20389L8 0Z"
                                                    fill="#FFBB00"/>
                                            </svg>
                                            <svg width="16" height="15" viewBox="0 0 16 15" fill="none"
                                                 xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M8 0L9.79611 5.52786H15.6085L10.9062 8.94427L12.7023 14.4721L8 11.0557L3.29772 14.4721L5.09383 8.94427L0.391548 5.52786H6.20389L8 0Z"
                                                    fill="#FFBB00"/>
                                            </svg>
                                            <svg width="16" height="15" viewBox="0 0 16 15" fill="none"
                                                 xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M8 0L9.79611 5.52786H15.6085L10.9062 8.94427L12.7023 14.4721L8 11.0557L3.29772 14.4721L5.09383 8.94427L0.391548 5.52786H6.20389L8 0Z"
                                                    fill="#FFBB00"/>
                                            </svg>
                                        </div>
                                        <div className="text-[#83808f] text-xs font-medium leading-[110%]">5 / 5.0
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="pt-4 pr-6 max-md:pr-0 max-md:pt-8">
                                <svg className="max-md:w-[58px] max-md:h-[42px]" width="83" height="51"
                                     viewBox="0 0 83 51"
                                     fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fillRule="evenodd" clipRule="evenodd"
                                          d="M28.3778 33.9444C27.9823 37.5887 26.9276 40.4984 25.0914 43.0786C22.6289 46.5204 18.4997 49.7362 11.6726 53.1544C11.2347 53.3469 10.8404 53.6265 10.5137 53.976C10.1871 54.3256 9.93483 54.7379 9.77236 55.1879C9.60989 55.6379 9.54056 56.1162 9.56857 56.5938C9.59658 57.0714 9.72136 57.5384 9.93532 57.9663C10.1493 58.3942 10.448 58.7742 10.8132 59.0832C11.1785 59.3921 11.6028 59.6237 12.0603 59.7637C12.5177 59.9037 12.9989 59.9493 13.4746 59.8977C13.9502 59.8461 14.4104 59.6984 14.8272 59.4636C22.1251 55.8193 27.4126 51.9726 30.8355 47.1748C34.3244 42.297 35.6145 36.8024 35.6145 30.4132V9.22566C35.6145 7.04039 34.7464 4.94462 33.2012 3.3994C31.656 1.85418 29.5602 0.986084 27.3749 0.986084H8.54159C6.35631 0.986084 4.26054 1.85418 2.71532 3.3994C1.1701 4.94462 0.302002 7.04039 0.302002 9.22566V25.7048C0.302002 30.2531 3.99334 33.9444 8.54159 33.9444H28.3778ZM75.4611 33.9444C75.0656 37.5887 74.011 40.4984 72.1747 43.0786C69.7123 46.5204 65.5831 49.7362 58.756 53.1544C58.318 53.3469 57.9237 53.6265 57.5971 53.976C57.2704 54.3256 57.0182 54.7379 56.8557 55.1879C56.6932 55.6379 56.6239 56.1162 56.6519 56.5938C56.6799 57.0714 56.8047 57.5384 57.0187 57.9663C57.2326 58.3942 57.5313 58.7742 57.8966 59.0832C58.2619 59.3921 58.6861 59.6237 59.1436 59.7637C59.6011 59.9037 60.0823 59.9493 60.5579 59.8977C61.0335 59.8461 61.4937 59.6984 61.9106 59.4636C69.2085 55.8193 74.4959 51.9726 77.9189 47.1748C81.4078 42.297 82.6979 36.8024 82.6979 30.4132V9.22566C82.6979 7.04039 81.8298 4.94462 80.2845 3.3994C78.7393 1.85418 76.6435 0.986084 74.4583 0.986084H55.6249C53.4397 0.986084 51.3439 1.85418 49.7987 3.3994C48.2534 4.94462 47.3853 7.04039 47.3853 9.22566V25.7048C47.3853 30.2531 51.0767 33.9444 55.6249 33.9444H75.4611Z"
                                          fill="#00130A"/>
                                </svg>
                            </div>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide className="slide border border-design border-transparent p-8 text-left rounded-lg max-md:pr-4">
                        <div
                            className="mb-6 text-[#06011e] text-base leading-normal max-md:text-sm max-md:leading-[150%] max-md:mb-6">
                            {t('honest_reviews.review_two_text')}
                        </div>
                        <div className="flex justify-between items-start gap-4">
                            <div className="flex items-center gap-6">
                                <div className="min-w-12 w-12 h-12 rounded-full"><img
                                    className="w-full h-full object-cover rounded-full" src="/img/serhiy_berezhny.jpg"/>
                                </div>
                                <div className="mt-[-8px] flex flex-col gap-1">
                                    <div
                                        className="text-[#06011e] text-base font-bold leading-tight max-md:text-sm">
                                        {t('honest_reviews.review_two_author')}
                                    </div>
                                    <div className="flex gap-2 items-center">
                                        <div className="flex justify-start items-center">
                                            <svg width="16" height="15" viewBox="0 0 16 15" fill="none"
                                                 xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M8 0L9.79611 5.52786H15.6085L10.9062 8.94427L12.7023 14.4721L8 11.0557L3.29772 14.4721L5.09383 8.94427L0.391548 5.52786H6.20389L8 0Z"
                                                    fill="#FFBB00"/>
                                            </svg>
                                            <svg width="16" height="15" viewBox="0 0 16 15" fill="none"
                                                 xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M8 0L9.79611 5.52786H15.6085L10.9062 8.94427L12.7023 14.4721L8 11.0557L3.29772 14.4721L5.09383 8.94427L0.391548 5.52786H6.20389L8 0Z"
                                                    fill="#FFBB00"/>
                                            </svg>
                                            <svg width="16" height="15" viewBox="0 0 16 15" fill="none"
                                                 xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M8 0L9.79611 5.52786H15.6085L10.9062 8.94427L12.7023 14.4721L8 11.0557L3.29772 14.4721L5.09383 8.94427L0.391548 5.52786H6.20389L8 0Z"
                                                    fill="#FFBB00"/>
                                            </svg>
                                            <svg width="16" height="15" viewBox="0 0 16 15" fill="none"
                                                 xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M8 0L9.79611 5.52786H15.6085L10.9062 8.94427L12.7023 14.4721L8 11.0557L3.29772 14.4721L5.09383 8.94427L0.391548 5.52786H6.20389L8 0Z"
                                                    fill="#FFBB00"/>
                                            </svg>
                                            <svg width="16" height="15" viewBox="0 0 16 15" fill="none"
                                                 xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M8 0L9.79611 5.52786H15.6085L10.9062 8.94427L12.7023 14.4721L8 11.0557L3.29772 14.4721L5.09383 8.94427L0.391548 5.52786H6.20389L8 0Z"
                                                    fill="#FFBB00"/>
                                            </svg>
                                        </div>
                                        <div className="text-[#83808f] text-xs font-medium leading-[110%]">5 / 5.0
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="pt-4 pr-6 max-md:pr-0 max-md:pt-8">
                                <svg className="max-md:w-[58px] max-md:h-[42px]" width="83" height="51"
                                     viewBox="0 0 83 51"
                                     fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fillRule="evenodd" clipRule="evenodd"
                                          d="M28.3778 33.9444C27.9823 37.5887 26.9276 40.4984 25.0914 43.0786C22.6289 46.5204 18.4997 49.7362 11.6726 53.1544C11.2347 53.3469 10.8404 53.6265 10.5137 53.976C10.1871 54.3256 9.93483 54.7379 9.77236 55.1879C9.60989 55.6379 9.54056 56.1162 9.56857 56.5938C9.59658 57.0714 9.72136 57.5384 9.93532 57.9663C10.1493 58.3942 10.448 58.7742 10.8132 59.0832C11.1785 59.3921 11.6028 59.6237 12.0603 59.7637C12.5177 59.9037 12.9989 59.9493 13.4746 59.8977C13.9502 59.8461 14.4104 59.6984 14.8272 59.4636C22.1251 55.8193 27.4126 51.9726 30.8355 47.1748C34.3244 42.297 35.6145 36.8024 35.6145 30.4132V9.22566C35.6145 7.04039 34.7464 4.94462 33.2012 3.3994C31.656 1.85418 29.5602 0.986084 27.3749 0.986084H8.54159C6.35631 0.986084 4.26054 1.85418 2.71532 3.3994C1.1701 4.94462 0.302002 7.04039 0.302002 9.22566V25.7048C0.302002 30.2531 3.99334 33.9444 8.54159 33.9444H28.3778ZM75.4611 33.9444C75.0656 37.5887 74.011 40.4984 72.1747 43.0786C69.7123 46.5204 65.5831 49.7362 58.756 53.1544C58.318 53.3469 57.9237 53.6265 57.5971 53.976C57.2704 54.3256 57.0182 54.7379 56.8557 55.1879C56.6932 55.6379 56.6239 56.1162 56.6519 56.5938C56.6799 57.0714 56.8047 57.5384 57.0187 57.9663C57.2326 58.3942 57.5313 58.7742 57.8966 59.0832C58.2619 59.3921 58.6861 59.6237 59.1436 59.7637C59.6011 59.9037 60.0823 59.9493 60.5579 59.8977C61.0335 59.8461 61.4937 59.6984 61.9106 59.4636C69.2085 55.8193 74.4959 51.9726 77.9189 47.1748C81.4078 42.297 82.6979 36.8024 82.6979 30.4132V9.22566C82.6979 7.04039 81.8298 4.94462 80.2845 3.3994C78.7393 1.85418 76.6435 0.986084 74.4583 0.986084H55.6249C53.4397 0.986084 51.3439 1.85418 49.7987 3.3994C48.2534 4.94462 47.3853 7.04039 47.3853 9.22566V25.7048C47.3853 30.2531 51.0767 33.9444 55.6249 33.9444H75.4611Z"
                                          fill="#00130A"/>
                                </svg>
                            </div>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide className="slide border border-design border-transparent p-8 text-left rounded-lg max-md:pr-4">
                        <div
                            className="mb-6 text-[#06011e] text-base leading-normal max-md:text-sm max-md:leading-[150%] max-md:mb-6">
                            {t('honest_reviews.review_three_text')}
                        </div>
                        <div className="flex justify-between items-start gap-4">
                            <div className="flex items-center gap-6">
                                <div className="min-w-12 w-12 h-12 rounded-full"><img
                                    className="w-full h-full object-cover rounded-full" src="/img/liya_vishnevska.jpg"/>
                                </div>
                                <div className="mt-[-8px] flex flex-col gap-1">
                                    <div
                                        className="text-[#06011e] text-base font-bold leading-tight max-md:text-sm">
                                        {t('honest_reviews.review_three_author')}
                                    </div>
                                    <div className="flex gap-2 items-center">
                                        <div className="flex justify-start items-center">
                                            <svg width="16" height="15" viewBox="0 0 16 15" fill="none"
                                                 xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M8 0L9.79611 5.52786H15.6085L10.9062 8.94427L12.7023 14.4721L8 11.0557L3.29772 14.4721L5.09383 8.94427L0.391548 5.52786H6.20389L8 0Z"
                                                    fill="#FFBB00"/>
                                            </svg>
                                            <svg width="16" height="15" viewBox="0 0 16 15" fill="none"
                                                 xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M8 0L9.79611 5.52786H15.6085L10.9062 8.94427L12.7023 14.4721L8 11.0557L3.29772 14.4721L5.09383 8.94427L0.391548 5.52786H6.20389L8 0Z"
                                                    fill="#FFBB00"/>
                                            </svg>
                                            <svg width="16" height="15" viewBox="0 0 16 15" fill="none"
                                                 xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M8 0L9.79611 5.52786H15.6085L10.9062 8.94427L12.7023 14.4721L8 11.0557L3.29772 14.4721L5.09383 8.94427L0.391548 5.52786H6.20389L8 0Z"
                                                    fill="#FFBB00"/>
                                            </svg>
                                            <svg width="16" height="15" viewBox="0 0 16 15" fill="none"
                                                 xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M8 0L9.79611 5.52786H15.6085L10.9062 8.94427L12.7023 14.4721L8 11.0557L3.29772 14.4721L5.09383 8.94427L0.391548 5.52786H6.20389L8 0Z"
                                                    fill="#FFBB00"/>
                                            </svg>
                                            <svg width="16" height="15" viewBox="0 0 16 15" fill="none"
                                                 xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M8 0L9.79611 5.52786H15.6085L10.9062 8.94427L12.7023 14.4721L8 11.0557L3.29772 14.4721L5.09383 8.94427L0.391548 5.52786H6.20389L8 0Z"
                                                    fill="#FFBB00"/>
                                            </svg>
                                        </div>
                                        <div className="text-[#83808f] text-xs font-medium leading-[110%]">5 / 5.0
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="pt-4 pr-6 max-md:pr-0 max-md:pt-8">
                                <svg className="max-md:w-[58px] max-md:h-[42px]" width="83" height="51"
                                     viewBox="0 0 83 51"
                                     fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fillRule="evenodd" clipRule="evenodd"
                                          d="M28.3778 33.9444C27.9823 37.5887 26.9276 40.4984 25.0914 43.0786C22.6289 46.5204 18.4997 49.7362 11.6726 53.1544C11.2347 53.3469 10.8404 53.6265 10.5137 53.976C10.1871 54.3256 9.93483 54.7379 9.77236 55.1879C9.60989 55.6379 9.54056 56.1162 9.56857 56.5938C9.59658 57.0714 9.72136 57.5384 9.93532 57.9663C10.1493 58.3942 10.448 58.7742 10.8132 59.0832C11.1785 59.3921 11.6028 59.6237 12.0603 59.7637C12.5177 59.9037 12.9989 59.9493 13.4746 59.8977C13.9502 59.8461 14.4104 59.6984 14.8272 59.4636C22.1251 55.8193 27.4126 51.9726 30.8355 47.1748C34.3244 42.297 35.6145 36.8024 35.6145 30.4132V9.22566C35.6145 7.04039 34.7464 4.94462 33.2012 3.3994C31.656 1.85418 29.5602 0.986084 27.3749 0.986084H8.54159C6.35631 0.986084 4.26054 1.85418 2.71532 3.3994C1.1701 4.94462 0.302002 7.04039 0.302002 9.22566V25.7048C0.302002 30.2531 3.99334 33.9444 8.54159 33.9444H28.3778ZM75.4611 33.9444C75.0656 37.5887 74.011 40.4984 72.1747 43.0786C69.7123 46.5204 65.5831 49.7362 58.756 53.1544C58.318 53.3469 57.9237 53.6265 57.5971 53.976C57.2704 54.3256 57.0182 54.7379 56.8557 55.1879C56.6932 55.6379 56.6239 56.1162 56.6519 56.5938C56.6799 57.0714 56.8047 57.5384 57.0187 57.9663C57.2326 58.3942 57.5313 58.7742 57.8966 59.0832C58.2619 59.3921 58.6861 59.6237 59.1436 59.7637C59.6011 59.9037 60.0823 59.9493 60.5579 59.8977C61.0335 59.8461 61.4937 59.6984 61.9106 59.4636C69.2085 55.8193 74.4959 51.9726 77.9189 47.1748C81.4078 42.297 82.6979 36.8024 82.6979 30.4132V9.22566C82.6979 7.04039 81.8298 4.94462 80.2845 3.3994C78.7393 1.85418 76.6435 0.986084 74.4583 0.986084H55.6249C53.4397 0.986084 51.3439 1.85418 49.7987 3.3994C48.2534 4.94462 47.3853 7.04039 47.3853 9.22566V25.7048C47.3853 30.2531 51.0767 33.9444 55.6249 33.9444H75.4611Z"
                                          fill="#00130A"/>
                                </svg>
                            </div>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide className="slide border border-design border-transparent p-8 text-left rounded-lg max-md:pr-4">
                        <div
                            className="mb-6 text-[#06011e] text-base leading-normal max-md:text-sm max-md:leading-[150%] max-md:mb-6">
                            {t('honest_reviews.review_four_text')}
                        </div>
                        <div className="flex justify-between items-start gap-4">
                            <div className="flex items-center gap-6">
                                <div className="min-w-12 w-12 h-12 rounded-full"><img
                                    className="w-full h-full object-cover rounded-full" src="/img/oksana_postrelko.jpg"/>
                                </div>
                                <div className="mt-[-8px] flex flex-col gap-1">
                                    <div
                                        className="text-[#06011e] text-base font-bold leading-tight max-md:text-sm">
                                        {t('honest_reviews.review_four_author')}
                                    </div>
                                    <div className="flex gap-2 items-center">
                                        <div className="flex justify-start items-center">
                                            <svg width="16" height="15" viewBox="0 0 16 15" fill="none"
                                                 xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M8 0L9.79611 5.52786H15.6085L10.9062 8.94427L12.7023 14.4721L8 11.0557L3.29772 14.4721L5.09383 8.94427L0.391548 5.52786H6.20389L8 0Z"
                                                    fill="#FFBB00"/>
                                            </svg>
                                            <svg width="16" height="15" viewBox="0 0 16 15" fill="none"
                                                 xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M8 0L9.79611 5.52786H15.6085L10.9062 8.94427L12.7023 14.4721L8 11.0557L3.29772 14.4721L5.09383 8.94427L0.391548 5.52786H6.20389L8 0Z"
                                                    fill="#FFBB00"/>
                                            </svg>
                                            <svg width="16" height="15" viewBox="0 0 16 15" fill="none"
                                                 xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M8 0L9.79611 5.52786H15.6085L10.9062 8.94427L12.7023 14.4721L8 11.0557L3.29772 14.4721L5.09383 8.94427L0.391548 5.52786H6.20389L8 0Z"
                                                    fill="#FFBB00"/>
                                            </svg>
                                            <svg width="16" height="15" viewBox="0 0 16 15" fill="none"
                                                 xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M8 0L9.79611 5.52786H15.6085L10.9062 8.94427L12.7023 14.4721L8 11.0557L3.29772 14.4721L5.09383 8.94427L0.391548 5.52786H6.20389L8 0Z"
                                                    fill="#FFBB00"/>
                                            </svg>
                                            <svg width="16" height="15" viewBox="0 0 16 15" fill="none"
                                                 xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M8 0L9.79611 5.52786H15.6085L10.9062 8.94427L12.7023 14.4721L8 11.0557L3.29772 14.4721L5.09383 8.94427L0.391548 5.52786H6.20389L8 0Z"
                                                    fill="#FFBB00"/>
                                            </svg>
                                        </div>
                                        <div className="text-[#83808f] text-xs font-medium leading-[110%]">5 / 5.0
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="pt-4 pr-6 max-md:pr-0 max-md:pt-8">
                                <svg className="max-md:w-[58px] max-md:h-[42px]" width="83" height="51"
                                     viewBox="0 0 83 51"
                                     fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fillRule="evenodd" clipRule="evenodd"
                                          d="M28.3778 33.9444C27.9823 37.5887 26.9276 40.4984 25.0914 43.0786C22.6289 46.5204 18.4997 49.7362 11.6726 53.1544C11.2347 53.3469 10.8404 53.6265 10.5137 53.976C10.1871 54.3256 9.93483 54.7379 9.77236 55.1879C9.60989 55.6379 9.54056 56.1162 9.56857 56.5938C9.59658 57.0714 9.72136 57.5384 9.93532 57.9663C10.1493 58.3942 10.448 58.7742 10.8132 59.0832C11.1785 59.3921 11.6028 59.6237 12.0603 59.7637C12.5177 59.9037 12.9989 59.9493 13.4746 59.8977C13.9502 59.8461 14.4104 59.6984 14.8272 59.4636C22.1251 55.8193 27.4126 51.9726 30.8355 47.1748C34.3244 42.297 35.6145 36.8024 35.6145 30.4132V9.22566C35.6145 7.04039 34.7464 4.94462 33.2012 3.3994C31.656 1.85418 29.5602 0.986084 27.3749 0.986084H8.54159C6.35631 0.986084 4.26054 1.85418 2.71532 3.3994C1.1701 4.94462 0.302002 7.04039 0.302002 9.22566V25.7048C0.302002 30.2531 3.99334 33.9444 8.54159 33.9444H28.3778ZM75.4611 33.9444C75.0656 37.5887 74.011 40.4984 72.1747 43.0786C69.7123 46.5204 65.5831 49.7362 58.756 53.1544C58.318 53.3469 57.9237 53.6265 57.5971 53.976C57.2704 54.3256 57.0182 54.7379 56.8557 55.1879C56.6932 55.6379 56.6239 56.1162 56.6519 56.5938C56.6799 57.0714 56.8047 57.5384 57.0187 57.9663C57.2326 58.3942 57.5313 58.7742 57.8966 59.0832C58.2619 59.3921 58.6861 59.6237 59.1436 59.7637C59.6011 59.9037 60.0823 59.9493 60.5579 59.8977C61.0335 59.8461 61.4937 59.6984 61.9106 59.4636C69.2085 55.8193 74.4959 51.9726 77.9189 47.1748C81.4078 42.297 82.6979 36.8024 82.6979 30.4132V9.22566C82.6979 7.04039 81.8298 4.94462 80.2845 3.3994C78.7393 1.85418 76.6435 0.986084 74.4583 0.986084H55.6249C53.4397 0.986084 51.3439 1.85418 49.7987 3.3994C48.2534 4.94462 47.3853 7.04039 47.3853 9.22566V25.7048C47.3853 30.2531 51.0767 33.9444 55.6249 33.9444H75.4611Z"
                                          fill="#00130A"/>
                                </svg>
                            </div>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide className="slide border border-design border-transparent p-8 text-left rounded-lg max-md:pr-4">
                        <div
                            className="mb-6 text-[#06011e] text-base leading-normal max-md:text-sm max-md:leading-[150%] max-md:mb-6">
                            {t('honest_reviews.review_five_text')}
                        </div>
                        <div className="flex justify-between items-start gap-4">
                            <div className="flex items-center gap-6">
                                <div className="min-w-12 w-12 h-12 rounded-full"><img
                                    className="w-full h-full object-cover rounded-full" src="/img/andriy_kim.jpg"/>
                                </div>
                                <div className="mt-[-8px] flex flex-col gap-1">
                                    <div
                                        className="text-[#06011e] text-base font-bold leading-tight max-md:text-sm">
                                        {t('honest_reviews.review_five_author')}
                                    </div>
                                    <div className="flex gap-2 items-center">
                                        <div className="flex justify-start items-center">
                                            <svg width="16" height="15" viewBox="0 0 16 15" fill="none"
                                                 xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M8 0L9.79611 5.52786H15.6085L10.9062 8.94427L12.7023 14.4721L8 11.0557L3.29772 14.4721L5.09383 8.94427L0.391548 5.52786H6.20389L8 0Z"
                                                    fill="#FFBB00"/>
                                            </svg>
                                            <svg width="16" height="15" viewBox="0 0 16 15" fill="none"
                                                 xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M8 0L9.79611 5.52786H15.6085L10.9062 8.94427L12.7023 14.4721L8 11.0557L3.29772 14.4721L5.09383 8.94427L0.391548 5.52786H6.20389L8 0Z"
                                                    fill="#FFBB00"/>
                                            </svg>
                                            <svg width="16" height="15" viewBox="0 0 16 15" fill="none"
                                                 xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M8 0L9.79611 5.52786H15.6085L10.9062 8.94427L12.7023 14.4721L8 11.0557L3.29772 14.4721L5.09383 8.94427L0.391548 5.52786H6.20389L8 0Z"
                                                    fill="#FFBB00"/>
                                            </svg>
                                            <svg width="16" height="15" viewBox="0 0 16 15" fill="none"
                                                 xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M8 0L9.79611 5.52786H15.6085L10.9062 8.94427L12.7023 14.4721L8 11.0557L3.29772 14.4721L5.09383 8.94427L0.391548 5.52786H6.20389L8 0Z"
                                                    fill="#FFBB00"/>
                                            </svg>
                                            <svg width="16" height="15" viewBox="0 0 16 15" fill="none"
                                                 xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M8 0L9.79611 5.52786H15.6085L10.9062 8.94427L12.7023 14.4721L8 11.0557L3.29772 14.4721L5.09383 8.94427L0.391548 5.52786H6.20389L8 0Z"
                                                    fill="#FFBB00"/>
                                            </svg>
                                        </div>
                                        <div className="text-[#83808f] text-xs font-medium leading-[110%]">5 / 5.0
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="pt-4 pr-6 max-md:pr-0 max-md:pt-8">
                                <svg className="max-md:w-[58px] max-md:h-[42px]" width="83" height="51"
                                     viewBox="0 0 83 51"
                                     fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fillRule="evenodd" clipRule="evenodd"
                                          d="M28.3778 33.9444C27.9823 37.5887 26.9276 40.4984 25.0914 43.0786C22.6289 46.5204 18.4997 49.7362 11.6726 53.1544C11.2347 53.3469 10.8404 53.6265 10.5137 53.976C10.1871 54.3256 9.93483 54.7379 9.77236 55.1879C9.60989 55.6379 9.54056 56.1162 9.56857 56.5938C9.59658 57.0714 9.72136 57.5384 9.93532 57.9663C10.1493 58.3942 10.448 58.7742 10.8132 59.0832C11.1785 59.3921 11.6028 59.6237 12.0603 59.7637C12.5177 59.9037 12.9989 59.9493 13.4746 59.8977C13.9502 59.8461 14.4104 59.6984 14.8272 59.4636C22.1251 55.8193 27.4126 51.9726 30.8355 47.1748C34.3244 42.297 35.6145 36.8024 35.6145 30.4132V9.22566C35.6145 7.04039 34.7464 4.94462 33.2012 3.3994C31.656 1.85418 29.5602 0.986084 27.3749 0.986084H8.54159C6.35631 0.986084 4.26054 1.85418 2.71532 3.3994C1.1701 4.94462 0.302002 7.04039 0.302002 9.22566V25.7048C0.302002 30.2531 3.99334 33.9444 8.54159 33.9444H28.3778ZM75.4611 33.9444C75.0656 37.5887 74.011 40.4984 72.1747 43.0786C69.7123 46.5204 65.5831 49.7362 58.756 53.1544C58.318 53.3469 57.9237 53.6265 57.5971 53.976C57.2704 54.3256 57.0182 54.7379 56.8557 55.1879C56.6932 55.6379 56.6239 56.1162 56.6519 56.5938C56.6799 57.0714 56.8047 57.5384 57.0187 57.9663C57.2326 58.3942 57.5313 58.7742 57.8966 59.0832C58.2619 59.3921 58.6861 59.6237 59.1436 59.7637C59.6011 59.9037 60.0823 59.9493 60.5579 59.8977C61.0335 59.8461 61.4937 59.6984 61.9106 59.4636C69.2085 55.8193 74.4959 51.9726 77.9189 47.1748C81.4078 42.297 82.6979 36.8024 82.6979 30.4132V9.22566C82.6979 7.04039 81.8298 4.94462 80.2845 3.3994C78.7393 1.85418 76.6435 0.986084 74.4583 0.986084H55.6249C53.4397 0.986084 51.3439 1.85418 49.7987 3.3994C48.2534 4.94462 47.3853 7.04039 47.3853 9.22566V25.7048C47.3853 30.2531 51.0767 33.9444 55.6249 33.9444H75.4611Z"
                                          fill="#00130A"/>
                                </svg>
                            </div>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide className="slide border border-design border-transparent p-8 text-left rounded-lg max-md:pr-4">
                        <div
                            className="mb-6 text-[#06011e] text-base leading-normal max-md:text-sm max-md:leading-[150%] max-md:mb-6">
                            {t('honest_reviews.review_six_text')}
                        </div>
                        <div className="flex justify-between items-start gap-4">
                            <div className="flex items-center gap-6">
                                <div className="min-w-12 w-12 h-12 rounded-full"><img
                                    className="w-full h-full object-cover rounded-full" src="/img/oleg_kashtelyan.jpg"/>
                                </div>
                                <div className="mt-[-8px] flex flex-col gap-1">
                                    <div
                                        className="text-[#06011e] text-base font-bold leading-tight max-md:text-sm">
                                        {t('honest_reviews.review_six_author')}
                                    </div>
                                    <div className="flex gap-2 items-center">
                                        <div className="flex justify-start items-center">
                                            <svg width="16" height="15" viewBox="0 0 16 15" fill="none"
                                                 xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M8 0L9.79611 5.52786H15.6085L10.9062 8.94427L12.7023 14.4721L8 11.0557L3.29772 14.4721L5.09383 8.94427L0.391548 5.52786H6.20389L8 0Z"
                                                    fill="#FFBB00"/>
                                            </svg>
                                            <svg width="16" height="15" viewBox="0 0 16 15" fill="none"
                                                 xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M8 0L9.79611 5.52786H15.6085L10.9062 8.94427L12.7023 14.4721L8 11.0557L3.29772 14.4721L5.09383 8.94427L0.391548 5.52786H6.20389L8 0Z"
                                                    fill="#FFBB00"/>
                                            </svg>
                                            <svg width="16" height="15" viewBox="0 0 16 15" fill="none"
                                                 xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M8 0L9.79611 5.52786H15.6085L10.9062 8.94427L12.7023 14.4721L8 11.0557L3.29772 14.4721L5.09383 8.94427L0.391548 5.52786H6.20389L8 0Z"
                                                    fill="#FFBB00"/>
                                            </svg>
                                            <svg width="16" height="15" viewBox="0 0 16 15" fill="none"
                                                 xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M8 0L9.79611 5.52786H15.6085L10.9062 8.94427L12.7023 14.4721L8 11.0557L3.29772 14.4721L5.09383 8.94427L0.391548 5.52786H6.20389L8 0Z"
                                                    fill="#FFBB00"/>
                                            </svg>
                                            <svg width="16" height="15" viewBox="0 0 16 15" fill="none"
                                                 xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M8 0L9.79611 5.52786H15.6085L10.9062 8.94427L12.7023 14.4721L8 11.0557L3.29772 14.4721L5.09383 8.94427L0.391548 5.52786H6.20389L8 0Z"
                                                    fill="#FFBB00"/>
                                            </svg>
                                        </div>
                                        <div className="text-[#83808f] text-xs font-medium leading-[110%]">5 / 5.0
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="pt-4 pr-6 max-md:pr-0 max-md:pt-8">
                                <svg className="max-md:w-[58px] max-md:h-[42px]" width="83" height="51"
                                     viewBox="0 0 83 51"
                                     fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fillRule="evenodd" clipRule="evenodd"
                                          d="M28.3778 33.9444C27.9823 37.5887 26.9276 40.4984 25.0914 43.0786C22.6289 46.5204 18.4997 49.7362 11.6726 53.1544C11.2347 53.3469 10.8404 53.6265 10.5137 53.976C10.1871 54.3256 9.93483 54.7379 9.77236 55.1879C9.60989 55.6379 9.54056 56.1162 9.56857 56.5938C9.59658 57.0714 9.72136 57.5384 9.93532 57.9663C10.1493 58.3942 10.448 58.7742 10.8132 59.0832C11.1785 59.3921 11.6028 59.6237 12.0603 59.7637C12.5177 59.9037 12.9989 59.9493 13.4746 59.8977C13.9502 59.8461 14.4104 59.6984 14.8272 59.4636C22.1251 55.8193 27.4126 51.9726 30.8355 47.1748C34.3244 42.297 35.6145 36.8024 35.6145 30.4132V9.22566C35.6145 7.04039 34.7464 4.94462 33.2012 3.3994C31.656 1.85418 29.5602 0.986084 27.3749 0.986084H8.54159C6.35631 0.986084 4.26054 1.85418 2.71532 3.3994C1.1701 4.94462 0.302002 7.04039 0.302002 9.22566V25.7048C0.302002 30.2531 3.99334 33.9444 8.54159 33.9444H28.3778ZM75.4611 33.9444C75.0656 37.5887 74.011 40.4984 72.1747 43.0786C69.7123 46.5204 65.5831 49.7362 58.756 53.1544C58.318 53.3469 57.9237 53.6265 57.5971 53.976C57.2704 54.3256 57.0182 54.7379 56.8557 55.1879C56.6932 55.6379 56.6239 56.1162 56.6519 56.5938C56.6799 57.0714 56.8047 57.5384 57.0187 57.9663C57.2326 58.3942 57.5313 58.7742 57.8966 59.0832C58.2619 59.3921 58.6861 59.6237 59.1436 59.7637C59.6011 59.9037 60.0823 59.9493 60.5579 59.8977C61.0335 59.8461 61.4937 59.6984 61.9106 59.4636C69.2085 55.8193 74.4959 51.9726 77.9189 47.1748C81.4078 42.297 82.6979 36.8024 82.6979 30.4132V9.22566C82.6979 7.04039 81.8298 4.94462 80.2845 3.3994C78.7393 1.85418 76.6435 0.986084 74.4583 0.986084H55.6249C53.4397 0.986084 51.3439 1.85418 49.7987 3.3994C48.2534 4.94462 47.3853 7.04039 47.3853 9.22566V25.7048C47.3853 30.2531 51.0767 33.9444 55.6249 33.9444H75.4611Z"
                                          fill="#00130A"/>
                                </svg>
                            </div>
                        </div>
                    </SwiperSlide>
                </Swiper>
            </div>
        </section>
    )
}