import {Parallax} from "react-scroll-parallax";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";

export default function RightParallaxContainer() {
    const {t} = useTranslation();
    const [translateYValue, setTranslateYValue] = useState(['-290px', '-90px']);

    useEffect(() => {
        const updateTranslateValue = () => {
            const width = window.innerWidth;
            if (width <= 1024) {
                setTranslateYValue(['-290px', '-100px']); // планшеты
            } else {
                setTranslateYValue(['-290px', '-90px']); // десктопы
            }
        };

        updateTranslateValue();
        window.addEventListener('resize', updateTranslateValue);

        return () => window.removeEventListener('resize', updateTranslateValue);
    }, []);

    return (
        <Parallax translateY={translateYValue}>
            <div
                className="border-design border-design--type4 !absolute -top-20 right-16 z-10 w-[338px] p-8 bg-white/80 rounded-lg flex flex-col justify-center items-start gap-8 text-left max-xl:-top-16 max-xl:right-0 max-[920px]:hidden">
                <div>
                    <svg width="64" height="65" viewBox="0 0 64 65" fill="none"
                         xmlns="http://www.w3.org/2000/svg">
                        <g opacity="0.7">
                            <path
                                d="M47.4986 19.8903V12.3338C47.4986 7.46277 43.5636 3.5 38.727 3.5H25.2732C20.4366 3.5 16.5016 7.46277 16.5016 12.3338V19.8908C8.35446 20.8094 2 27.7877 2 36.2331V57.2396C2 59.5889 3.89742 61.5 6.23028 61.5H57.7702C60.1028 61.5 62 59.5889 62 57.2396V36.2331C62 27.7877 55.6458 20.8094 47.4986 19.8903ZM18.2596 12.3338C18.2596 8.43934 21.4059 5.27016 25.2732 5.27016H38.727C42.5941 5.27016 45.7408 8.43934 45.7408 12.3338V19.7875C45.4129 19.7861 43.8857 19.7872 43.1188 19.7863V12.3338C43.1188 9.89509 41.1484 7.91075 38.727 7.91075H25.2732C22.8519 7.91075 20.8815 9.89509 20.8815 12.3338V20.2014C20.8815 20.6908 21.2754 21.0865 21.7603 21.0865C22.246 21.0865 22.6392 20.6908 22.6392 20.2014V12.3338C22.6392 10.8717 23.8211 9.68091 25.2732 9.68091H38.727C40.1796 9.68091 41.3608 10.8717 41.3608 12.3338V19.7863C39.0185 19.7872 18.5568 19.7861 18.2596 19.7875V12.3338ZM6.07559 59.7216C4.79812 59.6254 3.75798 58.5497 3.75798 57.2058V53.751H17.2059C17.6911 53.751 18.0847 53.3544 18.0847 52.866C18.0847 52.3771 17.6911 51.9804 17.2059 51.9804H3.75798V36.2331C3.75798 28.1409 10.2955 21.5565 18.3315 21.5565H45.669C53.6873 21.5565 60.2423 28.1258 60.2423 36.2331V51.9804H17.1657C16.681 51.9804 16.2869 52.3771 16.2869 52.866C16.2869 53.3544 16.681 53.751 17.1657 53.751H60.2423C60.2408 57.5282 60.246 57.2112 60.2345 57.3954C60.1357 58.724 59.0244 59.7112 57.804 59.7282C57.7791 59.7287 6.30376 59.736 6.07559 59.7216Z"
                                fill="#C3522F"/>
                            <path
                                d="M22.8641 41.7391H27.7606V46.636C27.7606 47.113 28.1474 47.5 28.6247 47.5H35.3753C35.8524 47.5 36.2394 47.113 36.2394 46.636V41.7391H41.1359C41.6134 41.7391 42 41.3523 42 40.875V34.125C42 33.6477 41.6134 33.2609 41.1359 33.2609H36.2394V28.364C36.2394 27.887 35.8524 27.5 35.3753 27.5H28.6247C28.1474 27.5 27.7606 27.887 27.7606 28.364V33.2609H22.8641C22.3868 33.2609 22 33.6477 22 34.125V40.875C22 41.3523 22.3868 41.7391 22.8641 41.7391ZM23.7283 34.989H28.6247C29.1022 34.989 29.4887 34.602 29.4887 34.125V29.2281H34.5113V34.125C34.5113 34.602 34.8981 34.989 35.3753 34.989H40.2719V40.011H35.3753C34.8981 40.011 34.5113 40.398 34.5113 40.875V45.7717H29.4887V40.875C29.4887 40.398 29.1022 40.011 28.6247 40.011H23.7283V34.989Z"
                                fill="#C3522F"/>
                        </g>
                    </svg>
                </div>
                <div className="text-[#06011e] text-lg font-bold leading-[130%] max-xl:text-base">
                    {t('reinterpretation_result.right_parallax_section')}
                </div>
            </div>
        </Parallax>
    )
}