import Contactus from "../MainPage/components/contactus/Contactus";
import {useEffect} from "react";
import {useTranslation} from "react-i18next";

export default function PrivacyPolicyPage() {
    const {t} = useTranslation();
    useEffect(() => {
        const hash = window.location.hash.substring(1); // Remove the `#`
        if (hash) {
            const element = document.getElementById(hash);
            if (element) {
                element.scrollIntoView({behavior: "smooth"});
            }
        }
    }, []);

    return (
        <>
            <section className="pt-28 max-md:pt-14" id="privacy">
                <div className="inside">
                    <div className="text-left text-lg tracking-wide text-[#00120a]  max-md:text-base">
                        <div

                            className="mb-20 text-center text-black text-[56px] font-bold leading-[120%] max-md:text-[32px] max-md:mb-10">
                            {t('privacy_policy_page.privacy')}<br/>{t('privacy_policy_page.policy')}
                        </div>
                        <div className="mb-10text-2xl font-bold max-md:mb-6">
                            {t('privacy_policy_page.collection_of_information_header')}
                        </div>
                        <div className="mb-14 font-normal max-md:text-base max-md:mb-8">
                            {t('privacy_policy_page.collection_of_information_text')}
                        </div>
                        <div className="mb-10 text-2xl font-bold max-md:mb-6">
                            {t('privacy_policy_page.use_of_information_header')}
                        </div>
                        <div className="mb-10 font-normal max-md:text-base max-md:mb-6">
                            {t('privacy_policy_page.use_of_information_text')}
                        </div>
                        <div className="mb-3 pl-[54px] justify-start items-center gap-5 flex max-md:pl-0">
                            <div className="w-2.5 h-2.5 bg-[#c3522f] rounded-sm"/>
                            <div className="grow shrink basis-0   font-normal ">
                                {t('privacy_policy_page.use_of_information_first_element')}
                            </div>
                        </div>
                        <div className="mb-3 pl-[54px] justify-start items-center gap-5 flex max-md:pl-0">
                            <div className="w-2.5 h-2.5 bg-[#c3522f] rounded-sm"/>
                            <div className="grow shrink basis-0   font-normal ">
                                {t('privacy_policy_page.use_of_information_second_element')}
                            </div>
                        </div>
                        <div className="mb-3 pl-[54px] justify-start items-center gap-5 flex max-md:pl-0">
                            <div className="w-2.5 h-2.5 bg-[#c3522f] rounded-sm"/>
                            <div className="grow shrink basis-0   font-normal ">
                                {t('privacy_policy_page.use_of_information_third_element')}
                            </div>
                        </div>
                        <div className="mt-14 mb-10 text-2xl font-bold max-md:mt-8 max-md:mb-6">
                            {t('privacy_policy_page.transfer_data_third_parties_header')}
                        </div>
                        <div className="mb-10 max-md:text-base max-md:mb-6">
                            {t('privacy_policy_page.transfer_data_third_parties_text')}
                        </div>
                        <div className="mb-5 text-[#418f44] font-bold">
                            {t('privacy_policy_page.information_protection_header')}
                        </div>
                        <div className="mb-10 max-md:mb-6">
                            {t('privacy_policy_page.information_protection_text')}
                        </div>
                        <div className="mb-5 text-[#418f44] font-bold">
                            {t('privacy_policy_page.information_saving_term_header')}
                        </div>
                        <div className="mb-14 max-md:mb-8">
                            {t('privacy_policy_page.information_saving_term_text')}
                        </div>
                        <div className="mb-10 text-2xl font-bold max-md:mb-6">
                            {t('privacy_policy_page.change_privacy_policy_header')}
                        </div>
                        <div className="mb-14 max-md:mb-8">
                            {t('privacy_policy_page.change_privacy_policy_text')}
                        </div>
                    </div>
                </div>
            </section>
            <section className="pb-12">
                <div className="inside">
                    <Contactus/>
                </div>
            </section>
        </>
    );
}
