import React, {useEffect, useState} from "react";
import {useForm} from "react-hook-form";
import {toast} from "react-toastify";
import {Link, useLocation, useNavigate} from "react-router-dom";
import {getResultsById} from "../../../../../../../api/getResultsById";
import Loader from "../../../../../../../components/loader/Loader";
import {getResultsByOrderId} from "../../../../../../../api/getResultsByOrderId";
import {useTranslation} from "react-i18next";

export default function ClientTabForm(props) {
    const { t } = useTranslation();
    const { i18n } = useTranslation();
    const {clientInterpretation, SetClientInterpretation} = props;
    const {
        register,
        setValue,
        setError,
        clearErrors,
        handleSubmit,
        reset,
        formState,
        formState: {errors, isSubmitting},
    } = useForm();
    const [isUploadData, SetUploadData] = useState(false);

    const location = useLocation();
    const navigate = useNavigate();
    const [interpretationByReference, SetInterpretationByReference] = useState(null);

    useEffect(() => {
        // http://localhost:3000/?code=aaa111&order=3018062&surname=ЮЩЕНКО

        const searchParams = new URLSearchParams(location.search);

        const orderId = searchParams.get('order_id');
        console.log(orderId)
        if (orderId) {
            const targetSection = document.getElementById('interpretation-section');
            targetSection.scrollIntoView({behavior: "smooth"});
            SetInterpretationByReference(
                {
                    orderId
                }
            )
        }
    }, [location]);

    const onAutomaticSubmit = async () => {

        console.log("Submitting form...");
        const formData = new FormData();
        formData.append("order_id", interpretationByReference.orderId);
        formData.append("lang", i18n.resolvedLanguage || i18n.language || 'uk');
        SetUploadData(true);
        let clientResult = await getResultsByOrderId(formData);
        if (!clientResult) return;
        SetUploadData(false);
        navigate(location.pathname, {replace: true});
        if (clientResult?.details) {
            toast.error(clientResult.details);
            return;
        }
        if (clientResult?.errors) {
            toast.error(clientResult.errors);
            return;
        } else if (!clientResult.errors) {
            SetClientInterpretation(clientResult);
            reset();
        }
        console.log("Submission complete");
    };


    useEffect(() => {
        if (clientInterpretation === null) {
            reset();
        }
    }, [clientInterpretation, reset]);

    useEffect(() => {
        if (interpretationByReference) {
            onAutomaticSubmit();
        }
    }, [interpretationByReference]);


    const onSubmit = handleSubmit(async (data) => {
        console.log(data);
        console.log('Submitting form...');

        const formData = new FormData();
        formData.append("dniprolab_order_id", data.order);
        formData.append("code", data.code);
        formData.append("last_name", data.surname);
        formData.append("anamnesis", data.anamnesis);
        formData.append("lang", i18n.resolvedLanguage || i18n.language || 'uk');
        SetUploadData(true);
        let clientResult = await getResultsById(formData);
        SetUploadData(false);
        if (!clientResult) return;
        console.log(clientResult);
        if (clientResult?.details) {
            toast.error(clientResult?.details);
            return;
        }
        if (clientResult?.errors) {
            toast.error(clientResult?.errors);
            return;
        } else if (!clientResult.errors) {
            SetClientInterpretation(clientResult);
            reset();
        }
        console.log('Submission complete');
    });

    return (
        <>
            <Loader isLoading={isUploadData}/>
            <form onSubmit={handleSubmit(onSubmit)} style={{display: 'grid', gap: '1rem'}}>
                <div className="w-full flex flex-col gap-2">
                    <div className="text-white text-base font-semibold leading-normal text-left">
                        {t('interpretation.surname_field')}
                    </div>
                    {errors?.surname?.type === "required" && (
                        <p className="form-review-error text-left">
                            {t('interpretation.surname_field_filled')}
                        </p>
                    )}
                    {errors?.surname?.type === "maxLength" && (
                        <p className="form-review-error text-left">
                            {t('interpretation.surname_field_many_letters')}
                        </p>
                    )}
                    {errors?.surname?.type === "pattern" && (
                        <p className="form-review-error text-left">
                            {t('interpretation.surname_field_pattern')}
                        </p>
                    )}
                    <input
                        type="text"
                        {...register("surname", {
                            required: true,
                            maxLength: 32,
                            pattern: /^[A-Za-zА-Яа-яЁёІіЇїЄєҐґ'’ʼ\s-]+$/
                        })}
                        placeholder={t('interpretation.surname_field_placeholder')}
                        className="h-12 px-4 py-3 rounded border border-[#b8b5b5] text-white text-base font-normal leading-normal text-left bg-transparent placeholder:text-[#595353]"/>
                </div>
                <div className="w-full flex flex-col gap-2">
                    <div className="text-white text-base font-semibold leading-normal text-left">
                        {t('interpretation.order_field')}
                    </div>
                    {errors?.order?.type === "required" && (
                        <p className="form-review-error text-left">
                            {t('interpretation.order_field_filled')}
                        </p>
                    )}
                    {errors?.order?.type === "maxLength" && (
                        <p className="form-review-error text-left">
                            Занадто багато символів
                            {t('interpretation.order_field_many_letters')}
                        </p>
                    )}
                    {errors?.order?.type === "pattern" && (
                        <p className="form-review-error text-left">
                            {t('interpretation.order_field_pattern')}
                        </p>
                    )}
                    <input
                        type="text"
                        {...register("order", {
                            required: true,
                            maxLength: 9,
                            pattern: /^[0-9]{3,9}$/
                        })}
                        placeholder="0000000"
                        className="h-12 px-4 py-3 rounded border border-[#b8b5b5] text-white text-base font-normal leading-normal text-left bg-transparent placeholder:text-[#595353]"/>
                </div>
                <div className="w-full flex flex-col gap-2">
                    <div className="text-white text-base font-semibold leading-normal text-left">
                        {t('interpretation.cod_field')}
                    </div>
                    {errors?.code?.type === "required" && (
                        <p className="form-review-error text-left">
                            {t('interpretation.cod_field_filled')}
                        </p>
                    )}
                    {errors?.code?.type === "pattern" && (
                        <p className="form-review-error text-left">
                            {t('interpretation.cod_field_pattern')}
                        </p>
                    )}
                    {errors?.code?.type === "maxLength" && (
                        <p className="form-review-error text-left">
                            {t('interpretation.cod_field_many_letters')}
                        </p>
                    )}
                    <input type="text"
                           {...register("code", {
                               required: true,
                               maxLength: 12,
                               pattern: /^[a-zA-Z0-9]{3,12}$/
                           })}
                           placeholder="000000"
                           className="h-12 px-4 py-3 rounded border border-[#b8b5b5] text-white text-base font-normal leading-normal text-left bg-transparent placeholder:text-[#595353]"/>
                </div>
                <div className="w-full flex flex-col gap-2">
                    <div className="text-white text-base font-semibold leading-normal text-left">
                        {t('interpretation.anamnesis_field')}
                    </div>
                    {errors?.anamnesis?.type === "required" && (
                        <p className="form-review-error text-left">
                            {t('interpretation.anamnesis_field_filled')}
                        </p>
                    )}
                    {errors?.anamnesis?.type === "maxLength" && (
                        <p className="form-review-error">
                            {t('interpretation.anamnesis_field_many_letters')}
                        </p>
                    )}
                    {errors?.anamnesis?.type === "pattern" && (
                        <p className="form-review-error text-left">
                            {t('interpretation.anamnesis_field_pattern')}
                        </p>
                    )}
                    <textarea
                        type="text"
                        {...register("anamnesis", {
                            // required: true,
                            maxLength: 1000,
                            pattern: /^[a-zA-Zа-яА-Я\dёЁіІїЇєЄ' ,.!@?-]+$/u,
                        })}
                        placeholder={t('interpretation.anamnesis_field_placeholder')}
                        className="h-48 px-4 py-3 rounded border border-[#b8b5b5] text-white text-base font-normal leading-normal text-left bg-transparent resize-y placeholder:text-[#595353]"/>
                </div>
                {errors?.terms && (
                    <p className="form-review-error text-left">{errors.terms.message}</p>
                )}
                <label
                    className="w-full mt-6 mb-8 flex items-start gap-2 cursor-pointer select-none max-md:mb-4 max-md:gap-4">

                    <input
                        type="checkbox"
                        {...register("terms", {
                            required: t('interpretation.terms_field_filled'),
                        })}
                        className="hidden-checkbox"/>
                    <div className="checkbox-box"></div>
                    <div className="text-white text-xs leading-[150%] text-left">
                        {t('interpretation.terms_field_left_confirmed')} &nbsp;&nbsp;
                        <Link to="/privacy-policy#privacy" className="text-[#418f44] underline hover:no-underline">
                            {t('interpretation.terms_field_right_confirmed')}
                        </Link>
                    </div>
                </label>
                <button className="button">
                    {t('interpretation.reject_interpretation')}
                </button>
            </form>
        </>
    )
}