// // не работает на мобилке -----------------------------------start
// import React, { useEffect, useState } from 'react';
// import { pdf } from '@react-pdf/renderer';
// import PdfReport from './components/PdfReport';
// import Loader from "../../components/loader/Loader";
//
// export default function PdfResultPage() {
//     const [pdfUrl, setPdfUrl] = useState(null);
//     const [pdfData, setPdfData] = useState(null);
//
//     useEffect(() => {
//         const storedData = localStorage.getItem("clientInterpretation");
//         if (storedData) {
//             setPdfData(JSON.parse(storedData));
//         }
//     }, []);
//
//     useEffect(() => {
//         if (pdfData) {
//             const generatePDF = async () => {
//                 try {
//                     const blob = await pdf(<PdfReport data={pdfData} />).toBlob();
//                     const url = URL.createObjectURL(blob);
//                     setPdfUrl(url);
//                 } catch (error) {
//                     console.error("Error generating PDF:", error);
//                 }
//             };
//
//             generatePDF();
//         }
//     }, [pdfData]);
//
//     if (!pdfData || !pdfUrl) return <div><Loader isLoading={true} /></div>;
//
//     return (
//         <section style={{ height: '100vh', width: '100%', overflow: 'hidden' }}>
//             {/*<iframe*/}
//             {/*    src={pdfUrl}*/}
//             {/*    style={{ border: 'none', height: '100%', width: '100%' }}*/}
//             {/*    title="PDF Report"*/}
//             {/*/>*/}
//             {/*<embed*/}
//             {/*    src={pdfUrl}*/}
//             {/*    type="application/pdf"*/}
//             {/*    style={{ height: '100%', width: '100%' }}*/}
//             {/*    title="PDF Report"*/}
//             {/*/>*/}
//             <object
//                 data={pdfUrl}
//                 type="application/pdf"
//                 style={{ height: '100%', width: '100%' }}
//                 aria-label="PDF Report"
//             >
//                 <p>Your browser does not support PDF viewing. Please download the file
//                     <a href={pdfUrl} download="report.pdf">here</a>.
//                 </p>
//             </object>
//         </section>
//     );
// }
// // не работает на мобилке -----------------------------------start

//import {pdf, PDFViewer} from '@react-pdf/renderer';
// import PdfReport from "./components/PdfReport";
// import PDFViewer from 'pdf-viewer-reactjs'
// export default function PdfResultPage() {
//     return (
//         <>
//             <section>
//                 <div className="inside">
//                     {/*<div className="py-16 max-xl:pt-20 max-xl:pb-14 max-md:pt-14 max-md:pb-12">*/}
//                     {/*    <PDFViewer style={{width: '100%', height: '100vh', overflow:'auto'}}>*/}
//                     {/*        <PdfReport />*/}
//                     {/*    </PDFViewer>*/}
//                     {/*</div>*/}
//
//                     <PDFViewer
//                         document={<PdfReport />}
//                     />
//                 </div>
//             </section>
//         </>
//     );
// }



// не работает на мобилке------------------------------------start
// import PdfReport from "./components/PdfReport";
// import {pdf, PDFViewer} from '@react-pdf/renderer';
//
// export default function PdfResultPage() {
//     const downloadPDF = async () => {
//         const blob = await pdf(<PdfReport/>).toBlob();
//         const url = URL.createObjectURL(blob);
//         const pdfWindow = window.open(url, "_blank");
//         pdfWindow.focus();
//     };
//
//     return (
//         <>
//             <section>
//                 <div className="inside">
//                     <div className="py-16 max-xl:pt-20 max-xl:pb-14 max-md:pt-14 max-md:pb-12">
//                         {/*<button onClick={downloadPDF} className="button pulsate">*/}
//                         {/*    Открыть PDF в новой вкладке*/}
//                         {/*</button>*/}
//                         <PDFViewer style={{width: '100%', height: '100vh'}}>
//                             <PdfReport/>
//                         </PDFViewer>
//                     </div>
//                 </div>
//             </section>
//         </>
//     );
// }


// import React, { useEffect, useState } from 'react';
// import { pdf } from '@react-pdf/renderer';
// import PdfReport from './components/PdfReport';
// import Loader from "../../components/loader/Loader";
// import { Worker, Viewer } from '@react-pdf-viewer/core';
// import '@react-pdf-viewer/core/lib/styles/index.css';
//
// export default function PdfResultPage() {
//     const [pdfBlob, setPdfBlob] = useState(null);
//     const [pdfData, setPdfData] = useState(null);
//
//     useEffect(() => {
//         const storedData = localStorage.getItem("clientInterpretation");
//         if (storedData) {
//             setPdfData(JSON.parse(storedData));
//         }
//     }, []);
//
//     useEffect(() => {
//         if (pdfData) {
//             const generatePDF = async () => {
//                 try {
//                     const blob = await pdf(<PdfReport data={pdfData} />).toBlob();
//                     setPdfBlob(blob);
//                 } catch (error) {
//                     console.error("Error generating PDF:", error);
//                 }
//             };
//
//             generatePDF();
//         }
//     }, [pdfData]);
//
//     if (!pdfData || !pdfBlob) return <div><Loader isLoading={true} /></div>;
//
//     const blobUrl = URL.createObjectURL(pdfBlob);
//
//     return (
//         <div style={{ height: '100vh' }}>
//             <Worker workerUrl="https://cdn.jsdelivr.net/npm/pdfjs-dist/build/pdf.worker.min.js">
//                 <Viewer fileUrl={blobUrl} />
//             </Worker>
//         </div>
//     );
// }

import { saveAs } from 'file-saver';
import React, { useEffect, useState } from 'react';
import { pdf } from '@react-pdf/renderer';
import PdfReport from './components/PdfReport';
import Loader from "../../components/loader/Loader";
import { Worker, Viewer } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
import pdfWorkerUrl from 'pdfjs-dist/build/pdf.worker.min.js';
import {useTranslation} from "react-i18next";

export default function PdfResultPage() {
    const {t} = useTranslation();
    const [pdfBlob, setPdfBlob] = useState(null);
    const [pdfData, setPdfData] = useState(null);

    useEffect(() => {
        const storedData = localStorage.getItem("clientInterpretation");
        if (storedData) {
            setPdfData(JSON.parse(storedData));
        }
    }, []);

    useEffect(() => {
        if (pdfData) {
            const generatePDF = async () => {
                try {
                    const blob = await pdf(<PdfReport data={pdfData} />).toBlob();
                    setPdfBlob(blob);
                } catch (error) {
                    console.error("Error generating PDF:", error);
                }
            };

            generatePDF();
        }
    }, [pdfData]);

    if (!pdfData || !pdfBlob) return <div><Loader isLoading={true} /></div>;

    const blobUrl = URL.createObjectURL(pdfBlob);

    const downloadPdf = () => {
        saveAs(pdfBlob, 'results.pdf');
    };

    return (
        <div style={{ height: '65vh' }}>
            <section className="pt-14 pb-8">
                <div className="inside flex justify-end">
                    <button onClick={downloadPdf} className="button pulsate">
                        {t('pdf_result_page.download_pdf_button')}
                    </button>
                </div>
            </section>
            <Worker workerUrl={pdfWorkerUrl}>
                <Viewer fileUrl={blobUrl} />
            </Worker>
        </div>
    );
}