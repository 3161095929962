import {configApi} from "./config";
import {toast} from "react-toastify";

export const getResultsById = async (clientData) => {
    const { baseIIUrl } = configApi;
    const url = `${baseIIUrl}/api/results-by-id`;

    try {
        const response = await fetch(url, {
            method: "POST",
            body: clientData,
            headers: {
                'Accept': 'application/json',
            },
        });

        if (!response.ok) {
            if (response.status >= 400 && response.status < 500) {
                toast.error(`Client Error (${response.status}): ${response.statusText || "Unknown error"}`);
                //throw new Error(`Client Error (${response.status}): ${response.statusText || "Unknown error"}`);
                return;
            }
            if (response.status >= 500) {
                toast.error(`Server Error (${response.status}): Please try again later.`);
                //throw new Error(`Server Error (${response.status}): Please try again later.`);
                return;
            }
        }

        const data = await response.json();
        return data;
    } catch (error) {
        toast.error(`The server is temporarily unavailable, try again later`);
        return null;
    }
};
