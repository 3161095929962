import Contactus from "../MainPage/components/contactus/Contactus";
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";

export default function NotFoundPage() {
    const {t} = useTranslation();
    return (
        <>
            <section>
                <div className="inside">
                    <div className="py-32 max-xl:pt-20 max-xl:pb-14 max-md:pt-14 max-md:pb-12">
                        <div>
                            <img
                                className="m-auto max-w-full max-h-full object-cover"
                                src="/img/icon404.svg"
                                alt="Сторiнку не знайдено"
                            />
                        </div>
                        <div className="mb-10 text-[#00120a] text-2xl font-bold leading-[140%]">
                            {t('page_not_found.oops_what_happened')}
                        </div>
                        <div className="mb-10 text-center text-[#00120a] text-lg font-normal leading-[140%]">
                            {t('page_not_found.reenable_the_page')}
                        </div>
                        <Link to="/" className="button">
                            {t('page_not_found.button_to_main_page')}
                        </Link>
                    </div>
                    <div className="pb-14">
                        <Contactus/>
                    </div>
                </div>
            </section>
        </>
    );
}
