import React, {useState} from "react";
import {Link} from "react-router-dom";
import OneHealer from "./components/oneHealer/OneHealer";
import MakeAnAppointmentModal from "./components/oneHealer/components/makeAnAppointmentModal/MakeAnAppointmentModal";
import {useTranslation} from "react-i18next";

export default function RecommendedHealers(props) {
    const {t} = useTranslation();
    const {clientInterpretation} = props;
    const healers = clientInterpretation?.add_to_visit_clinic;
    const [selectedHealer, setSelectedHealer] = useState(null);

    const handleAppointmentClick = (healer) => {
        setSelectedHealer(healer);
    };
    if (healers.length > 0) {
        return (
            <section
                className="py-6 max-xl:py-10 max-md:py-6 bg-[url('/public/img/section-bg1.svg')] bg-no-repeat bg-left-bottom">
                <div className="inside">
                    <div className="mb-2 text-[#b8b5b5] text-base leading-normal">
                        {t('recommended_healers.check_yourself')}
                    </div>
                    <div
                        className="mb-12 text-[#00120a] text-[40px] font-bold leading-[130%] max-md:text-2xl max-md:mb-8">
                        {t('recommended_healers.title')}
                    </div>
                    <div className="mb-12 grid grid-cols-3 gap-6 max-xl:grid-cols-2 max-md:grid-cols-1 max-md:mb-8">
                        {healers.map((oneHealer, index) => (
                            <OneHealer
                                key={index}
                                oneHealer={oneHealer}
                                specialist={clientInterpretation?.data?.specialist}
                                onAppointmentClick={handleAppointmentClick}
                            />
                        ))}
                    </div>
                    <Link className="button button--type2" to="https://yakistzhyttia.com.ua/nashi_likari/"
                          target="_blank">
                        {t('recommended_healers.see_more_doctors')}
                    </Link>
                </div>
                <MakeAnAppointmentModal healer={selectedHealer}/>
            </section>
        );
    } else {
        return (
            <section className="py-10 max-xl:py-10 max-md:py-6">
                <div className="inside">
                    <div className="mb-2 text-[#b8b5b5] text-base leading-normal">
                        {t('recommended_healers.check_yourself')}
                    </div>
                    <Link className="button button pulsate" to="https://yakistzhyttia.com.ua/nashi_likari/"
                          target="_blank">
                        {t('recommended_healers.view_doctors')}
                    </Link>
                </div>
            </section>
        );
    }
}